<template>
<aheader />
<section class="section">
  <div class="columns">
    <div class="column is-8 is-offset-2">
      Step
      <span class="icon has-text-success is-big">
        <i class="fa-solid fa-1 fa-lg"></i>
      </span>
      <progress class="progress is-success" value="25" max="100"></progress>
    </div>
  </div>
  <div class="error-message notification is-danger is-light" v-show="errorMsg != ''" v-html="errorMsg"></div>
  <section class="section">
    <div class="columns">
      <div class="column is-8 is-offset-2">
        <div class="box">
          <div class="title is-4">
            ご購入者様情報
          </div>
          <div class="columns">
            <div class="column is-offset-2 pb-0">
              <div class="has-text-left is-size-5 has-text-weight-semibold">
                お名前
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-4 is-offset-2 pb-0">
              <div class="control">
                <input class="input" type="name" placeholder="姓" v-model="$store.state.order_customer.familyName">
              </div>
            </div>
            <div class="column is-4 pb-0">
              <div class="control">
                <input class="input" type="name" placeholder="名" v-model="$store.state.order_customer.firstName">
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-4 is-offset-2">
              <div class="control">
                <input class="input" type="name" placeholder="セイ" v-model="$store.state.order_customer.kanaFamily">
              </div>
            </div>
            <div class="column is-4">
              <div class="control">
                <input class="input" type="name" placeholder="メイ" v-model="$store.state.order_customer.kanaFirst">
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-offset-2 pb-0">
              <div class="has-text-left is-size-5 has-text-weight-semibold">
                生年月日
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-8 is-offset-2">
              <div class="select">
                <select v-model="$store.state.order_customer.birthYear">
                  <option v-for="year, key in years" :key='key'>{{year}}年</option>
                </select>
              </div>
              <div class="select">
                <select v-model="$store.state.order_customer.birthMonth">
                  <option v-for="month, key in months" :key='key'>{{month}}月</option>
                </select>
              </div>
              <div class="select">
                <select v-model="$store.state.order_customer.birthDay">
                  <option v-for="day, key in days" :key='key'>{{day}}日</option>
                </select>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-offset-2 pb-0">
              <div class="has-text-left is-size-5 has-text-weight-semibold">
                性別
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-8 is-offset-2">
              <div class="control">
                <label class="radio">
                  <input type="radio" name="answer" value="男性" v-model="$store.state.order_customer.picked">
                  男性
                </label>
                <label class="radio">
                  <input type="radio" name="answer" value="女性" v-model="$store.state.order_customer.picked">
                  女性
                </label>
                <label class="radio">
                  <input type="radio" name="answer" value="指定しない" v-model="$store.state.order_customer.picked">
                  指定しない
                </label>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-offset-2 pb-0">
              <div class="has-text-left is-size-5 has-text-weight-semibold">
                ご住所
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-3 is-offset-2 pb-0">
              <div class="has-text-left m-1">
                郵便番号
              </div>
              <div class="control">
                <input class="input" type="text" placeholder="半角数字" v-model="$store.state.order_customer.postCode" v-mask="'###-####'" inputmode="numeric">
              </div>
            </div>
            <div class="column is-3 pb-0">
              <div class="has-text-left m-1">
                都道府県
              </div>
              <div class="select control">
                <select v-model="$store.state.order_customer.pref">
                  <option v-for="pref, index in prefs" :key='index'>{{pref}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-8 is-offset-2">
              <div class="has-text-left mt-3">
                都道府県以降の住所
              </div>
              <div class="control">
                <input class="input" type="text" placeholder="都道府県以降の住所をご記入ください。" v-model="$store.state.order_customer.address">
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-offset-2 pb-0">
              <div class="has-text-left is-size-5 has-text-weight-semibold">
                電話番号
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-8 is-offset-2">
              <div class="control has-icons-left">
                <input class="input" type="phone" placeholder="電話番号" v-model="$store.state.order_customer.tel">
                <span class="icon is-small is-left">
                  <i class="fas fa-phone"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-offset-2 pb-0">
              <div class="has-text-left is-size-5 has-text-weight-semibold">
                メールアドレス
              </div>
            </div>
          </div>
          <div class="columns mb-4">
            <div class="column is-8 is-offset-2">
              <div class="control has-icons-left">
                <input class="input" type="email" placeholder="メールアドレス" v-model="$store.state.order_customer.eMail">
                <span class="icon is-small is-left">
                  <i class="fas fa-envelope"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="columns">
    <div class="column is-3 is-offset-2">
      <button class="button has-background-info-dark has-text-white-ter" @click="pushPage('cart')">
        <span class="icon is-small">
          <i class="fa-solid fa-arrow-left"></i>
        </span>
        <span>
          カートに戻る
        </span>
      </button>
    </div>
    <div class="column is-3 is-offset-2">
      <button class="button has-background-info-dark has-text-white-ter" @click="goNext()">
        <span>
          次にすすむ
        </span>
        <span class="icon is-small">
          <i class="fa-solid fa-arrow-right"></i>
        </span>
      </button>
    </div>
  </div>
</section>
<afooter/>
</template>
<script>
export default {
  data() {
    return {
      errorMsg:"",
      years: [...Array(100)].map((_, i) => (new Date()).getFullYear()-18-i),
      months: ["-", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      days: ["-", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
      prefs:["北海道",  "青森県",  "岩手県",  "宮城県",  "秋田県",  "山形県",  "福島県",  "茨城県",
              "栃木県",  "群馬県",  "埼玉県",  "千葉県",  "東京都",  "神奈川県",  "新潟県",  "富山県",
              "石川県",  "福井県",  "山梨県",  "長野県",  "岐阜県",  "静岡県",  "愛知県",  "三重県",
              "滋賀県",  "京都府",  "大阪府",  "兵庫県",  "奈良県",  "和歌山県",  "鳥取県",  "島根県",
              "岡山県",  "広島県",  "山口県",  "徳島県",  "香川県",  "愛媛県",  "高知県",  "福岡県",
              "佐賀県",  "長崎県",  "熊本県",  "大分県",  "宮崎県",  "鹿児島県", "沖縄県"]
    }
  },
  mounted() {
    this.scrollTop();
  },
  methods: {
    scrollTop() {
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      })
    },
    pushPage(page) {
      this.$router.push(page);
    },
    addErrMsg(msg) {
      if (this.errorMsg != "") this.errorMsg += "<br/>";
      this.errorMsg = this.errorMsg + msg;
    },
    goNext() {
      this.errorMsg = "";
      if (this.$store.state.order_customer.familyName == "")  this.addErrMsg("姓（漢字）を入力してください。");
      if (this.$store.state.order_customer.firstName == "")  this.addErrMsg("名（漢字）を入力してください。");
      if (this.$store.state.order_customer.kanaFamily == "") this.addErrMsg("姓（カタカナ）を入力してください。");
      if (this.$store.state.order_customer.kanaFirst == "") this.addErrMsg("名（カタカナ）を入力してください。");
      if (this.$store.state.order_customer.postCode == "") this.addErrMsg("郵便番号を入力してください。");
      if (this.$store.state.order_customer.pref == "") this.addErrMsg("都道府県を選択してください。")
      if (this.$store.state.order_customer.address == "") this.addErrMsg("住所を入力してください。");
      if (this.$store.state.order_customer.tel == "") this.addErrMsg("電話番号を入力してください。");
      if (this.$store.state.order_customer.eMail == "") this.addErrMsg("メールアドレスを入力してください。");
      if (this.errorMsg == "") {
        this.$router.push('order_delivery');
      } else {
        this.scrollTop();
      }
    }
  }
}
</script>
